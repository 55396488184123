// ** React Imports
// ** Store & Actions
// ** Third Party Components
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { toast, } from "react-toastify"
// ** Custom Components
// ** Hooks, context & utils
// ** Styles
// ** Images

// Conf
import initialState from "../../configs/api"

const executeCommand = createAsyncThunk(
  "api/execute",
  async (params, thunkAPI) => {
    const {
      intl = null,
      url = "/",
      method = "get",
      parameters = {},
      ...rest
    } = params

    const config = {...thunkAPI.getState().api.conf, ...rest}
    const token = sessionStorage.getItem("token") || localStorage.getItem("token")

    const requestParams = {
      url,
      method,
      baseURL: config.baseUrl,
      headers: {
        "Content-Type": "multipart/form-data",
        ...(token ? {Authorization: token} : null)
      },
      ...(method === "get" ? {params: parameters} : null),
      ...(method === "post" || method === "put" || method === "delete" || method === "patch"
        ? {data: Object.keys(parameters).reduce((accumulator, currentValue) => {
          accumulator.append(currentValue, parameters[currentValue])
          return accumulator
        }, new FormData())}
        : null),
      timeout: config.timeout * 1000,
      responseType: config.responseType,
      responseEncoding: config.responseEncoding,
      // validateStatus: (status) => {
      validateStatus: () => {
        // console.log(`%c Status : ${status}`, "background: blue; color: white")
        return true
      },
    }

    return await axios.request(requestParams)
      .then(response => {
        if (response.data.debug) {
          // eslint-disable-next-line no-console
          console.log("%c Status : ", "background: #222; color: #bada55", response.status)
          // eslint-disable-next-line no-console
          console.log("%c Debug : ", "background: #222; color: #bada55", response.data.debug)
          // eslint-disable-next-line no-console
          console.log("%c Errors : ", "background: #222; color: #bada55", response.data.errors)
        }

        if (response.data.errors.length) {
          // console.log(response.data.errors)
          response.data.errors.forEach(error => {
            if (error.message === "tokenBlacklisted" || error.message === "tokenExpired" || error.message === "tokenInvalid") {
              localStorage.removeItem("token")
              sessionStorage.removeItem("token")
              // eslint-disable-next-line no-use-before-define
              logout()
            }
            toast.error(intl?.formatMessage({id: error.message}) || error.message)
          })
        }

        if (response.headers.authorization) {
          sessionStorage.setItem("token", response.headers.authorization)
        }
        return { errors: response.data?.errors || [], data: response.data?.data || [], status: response.status, }
      })
      .catch((error) => {
        const errCode = error.code === "ECONNABORTED" ? "commandTimeout" : "apiUnavailable"
        toast.error(intl?.formatMessage({id: errCode}) || errCode)
        return { errors: [{ message: error.code === "ECONNABORTED" ? "commandTimeout" : "apiUnavailable", }], data: [], }
      })

  }
)

export const apiSlice = createSlice({
  name: "api",
  initialState,
  reducers: {
    login: (state, action) => {
      const { user = null, } = action.payload
      return { ...state, user, }
    },
    logout: (state) => {
      return { ...state, user: null, }
    },
    storeUser: (state, action) => {
      const { user = null, } = action.payload
      return { ...state, user: {...state.user, ...user}, }
    },
    storeAccount: (state, action) => {
      const { account = null, } = action.payload
      return { ...state, user: {...state.user, account: {...state.user.account, ...account}}, }
    },
    storeCompany: (state, action) => {
      const { company = null, } = action.payload
      return { ...state, user: {...state.user, account: {...state.user.account, company: {...state.user.account.company, ...company}}}, }
    },
  },
  extraReducers: (builder) => {
    builder
      // eslint-disable-next-line no-unused-vars
      .addCase(executeCommand.pending, (state, { payload }) => {})
      // eslint-disable-next-line no-unused-vars
      .addCase(executeCommand.fulfilled, (state, { payload }) => {})
      // eslint-disable-next-line no-unused-vars
      .addCase(executeCommand.rejected, (state, action) => {})
  },
})

export const {
  login,
  logout,
  storeUser,
  storeAccount,
  storeCompany,
} = apiSlice.actions

export { executeCommand, }

export default apiSlice.reducer