// ** React Imports
import React, { createContext, useContext, useState, } from "react"
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Styles
// ** Images

import appConfig from "../../configs/app"

const AppContext = createContext()
const AppSetUserContext = createContext()
// const AppConsumer = AppContext.Consumer

const useApp = () => useContext(AppContext)
const useUserConnected = () => useContext(AppSetUserContext)

const AppProvider = ({children}) => {
  const [ user, setUser ] = useState(null)
  const setUserPart = newUser => {
    setUser({
      ...newUser,
      ...{account: user.account},
    })
  }
  const setAccountPart = newAccount => {
    setUser({
      ...user,
      ...{
        account: {...newAccount, ...{company: user.account.company}}
      },
    })
  }
  const setCompanyPart = newCompany => {
    setUser({
      ...user,
      ...{
        account: {...user.account, ...{company: newCompany}}
      },
    })
  }

  return (
    <AppContext.Provider value={appConfig}>
      <AppSetUserContext.Provider value={[ user, setUser, setUserPart, setAccountPart, setCompanyPart, ]}>
        {children}
      </AppSetUserContext.Provider>
    </AppContext.Provider>
  )
}

// export { AppConsumer, useApp, AppProvider, }
export { useApp, useUserConnected, AppProvider, }