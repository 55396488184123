import ReactGA from "react-ga"

ReactGA.initialize("UA-216921674-1", {
  // debug: true,
})

const app = {
  config:{
    // appName: "PerkTek",
    // appLogoImage: require("../assets/images/logo/logo.svg").default,
    defaultLocale: "fr",
    // locale: "",
    defaultPageTitle: "PerkTek",
    // googleAnalyticsId: "UA-216921674-1",
    defaultVatRate: 20,
    titles: [ "mister", "madam", "miss", "doctor", ],
  },
  ReactGA,
}

export default app