// ** React Imports
import React, { useState, createContext } from "react"
// ** Store & Actions
// ** Third Party Components
import { IntlProvider } from "react-intl"
// ** Custom Components
// ** Hooks, context & utils
// ** Styles
// ** Images

import app from "../../configs/app"

// ** Core Language Data
import appFr from "../../assets/data/locales/app/fr.json"
import appEn from "../../assets/data/locales/app/en.json"

// ** Menu Language Data
import menuFr from "../../assets/data/locales/menu/fr.json"
import menuEn from "../../assets/data/locales/menu/en.json"

// ** Messages Language Data
import messagesFr from "../../assets/data/locales/messages/fr.json"
import messagesEn from "../../assets/data/locales/messages/en.json"

// ** Menu msg obj
const translations = {
  fr: { ...appFr, ...menuFr, ...messagesFr, },
  en: { ...appEn, ...menuEn, ...messagesEn, },
}

const language = localStorage.getItem("language") || (() => {
  localStorage.setItem("language", app.config.defaultLocale)
  return app.config.defaultLocale
})()

// ** Create context
const IntlContext = createContext()

const IntlProviderWrapper = ({ children }) => {

  // ** States
  const [ locale, setLocale ] = useState(language)
  const [ messages, setMessages ] = useState(translations[language])

  // ** Switches Language
  const switchLanguage = lang => {
    setLocale(lang)
    setMessages(translations[lang])
    localStorage.setItem("language", lang)
  }

  return (
    <IntlContext.Provider value={{ locale, switchLanguage }}>
      <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale={language}>
        {children}
      </IntlProvider>
    </IntlContext.Provider>
  )
}

export { IntlProviderWrapper, IntlContext, }