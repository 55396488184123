// ** React Imports
// ** Store & Actions
// ** Third Party Components
import { createSlice, } from "@reduxjs/toolkit"
// ** Custom Components
// ** Hooks, context & utils
// ** Styles
// ** Images

// Conf
import initialState from "../../configs/stripe"

export const stripeSlice = createSlice({
  name: "stripe",
  initialState,
  reducers: {
    // login: (state, action) => {
    //   const { user = null, } = action.payload
    //   return { ...state, user, }
    // },
    // logout: (state) => {
    //   return { ...state, user: null, }
    // },
    // storeUser: (state, action) => {
    //   const { user = null, } = action.payload
    //   return { ...state, user: {...state.user, ...user}, }
    // },
    // storeAccount: (state, action) => {
    //   const { account = null, } = action.payload
    //   return { ...state, user: {...state.user, account: {...state.user.account, ...account}}, }
    // },
    // storeCompany: (state, action) => {
    //   const { company = null, } = action.payload
    //   return { ...state, user: {...state.user, account: {...state.user.account, company: {...state.user.account.company, ...company}}}, }
    // },
  },
})

// export const {
//   login,
//   logout,
//   storeUser,
//   storeAccount,
//   storeCompany,
//  } = stripeSlice.actions

export default stripeSlice.reducer