// ** React Imports
import React, { createContext, useContext, } from "react"
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Styles
// ** Images

import layoutConfig from "../../configs/layout"

const LayoutContext = createContext()
// const LayoutConsumer = LayoutContext.Consumer
const useLayout = () => useContext(LayoutContext)

const LayoutProvider = ({children}) => (
  <LayoutContext.Provider value={layoutConfig}>
    {children}
  </LayoutContext.Provider>
)

// export {LayoutConsumer, useLayout, LayoutProvider}
export { useLayout, LayoutProvider, }