const stripe = {
  baseUrl: "https://api.stripe.com/v1",
  timeout: 1,
  responseType: "json",
  responseEncoding: "utf8",
  account: "acct_1JyfGkLhKxPYXuoX",
  publicLiveKey: "pk_live_51JyfGkLhKxPYXuoXCXrblYoFbHQDA7PtR8eOYLFOYU9ewc5ZRVFjtMFE60Vd3dLT6S5cUbh1hYqpeprjSuUIgZPf004MBun4ey",
  publicTestKey: "pk_test_51JyfGkLhKxPYXuoXR72biB9fnPlUEDWPzLf1vvdKqVYqSYoa8gdHJpF9DMwq0Dr7AW1Pk6ovkKyeQZOjcT1StrcK00HeCqb0Ll",
}

export default stripe