// ** React Imports
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Styles
import "./scss/style.scss"
// ** Images
import logo from "../../assets/images/logo/logo.svg"

const FallbackSpinner = () => {
  return (
    <div className="loading-spinner">
      <div className="fallback-container">
        <img className="loading-logo" src={logo} alt="logo" />
        <div className="effect-1"></div>
        <div className="effect-2"></div>
        <div className="effect-3"></div>
      </div>
    </div>
  )
}

export default FallbackSpinner
