// ** React Imports
import React, { StrictMode, Suspense, lazy } from "react"
// ** Store & Actions
import { Provider } from "react-redux"
import { store } from "./store/store"
// ** Third Party Components
import ReactDOM from "react-dom/client"
import { ToastContainer } from "react-toastify"
// ** Custom Components
import FallbackSpinner from "./components/spinner/FallbackSpinner"
// ** Hooks, context & utils
import { AppProvider } from "./utility/context/App"
import { LayoutProvider } from "./utility/context/Layout"
import { IntlProviderWrapper } from "./utility/context/Internationalization"
// ** Styles
import "react-perfect-scrollbar/dist/css/styles.css" // React Perfect Scrollbar
import "react-toastify/dist/ReactToastify.css" // React Toastify
import "./assets/fonts/feather/iconfont.css"
import "./assets/fonts/materialIcons/icon.css"
import "./assets/scss/core.scss"
// ** Images

// ** Lazy load app
const LazyApp = lazy(() => import("./App"))

// import reportWebVitals from "./reportWebVitals"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <StrictMode>
    <Provider store={store}>
      <Suspense fallback={<FallbackSpinner />}>
        <AppProvider>
          <LayoutProvider>
            <IntlProviderWrapper>
              <LazyApp />
              <ToastContainer autoClose={3000} newestOnTop />
            </IntlProviderWrapper>
          </LayoutProvider>
        </AppProvider>
      </Suspense>
    </Provider>
  </StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
