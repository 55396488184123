const api = {
  baseUrl: "https://api.perktek.com",
  // baseUrl: "https://testapi.perktek.com",
  timeout: 10,
  responseType: "json",
  responseEncoding: "utf8",
  publicKey: "-----BEGIN PUBLIC KEY-----\n" +
              "MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA4YMZu9iLZjnWBzqj+V4Y\n" +
              "BOspScoGw+n4xc3K4UEmlp1xmB/9ses76wQPkHv2EqpkpYpdoPJS5F44PnJi9aoL\n" +
              "LMcTfJkxT1RNRsv9waLvFVhvnP5b6Ble5/Ia4p+kRF73VmcayKPZhVnJvdDkXzJ9\n" +
              "eAwf/r02rjqOqoIcqc/Yhup8FLD6ude8OCZresmag95HnJEqlFRboAxnjzAF23Pt\n" +
              "olgMVaY79Y99zBWUlOBYkYcnHXNY4qkgWGxWtpu8/LOzQ9UMq0ilERDwOZQ8/Tb1\n" +
              "skf46z98jp9vUThFAjk0iKzQw143c4eX7e5zIil2fRXBedr/blLU24fJE3AHt6cD\n" +
              "t0JkhTSiI6y1/SyUdvswEbOmxuC3XIxYMI5kdgdD49CnFQ2fVtHfT+56E971ILC/\n" +
              "SU+WpDO5ZkgOJSnnKMGPNaqqbjBNrzCWPnn3nKi7pVxCk8YhGk/8rOWaOlgvny3b\n" +
              "i9L2VTURZI0We8Ikc3uDWde+2semT00Xrwli6a9Q2Vu7DHuWw5UfUrBMoBLZe6Hp\n" +
              "LcRH+P0iHHjc0FT2aqLjg0tIMo26hS9Z2otULxXbXnxVB9tfbMiRv1nlCRbs7e0O\n" +
              "sFzb4r0bgLl9APPUiaxtNv5yhCBo2K1r86VqcfF3urK2wATmYFzZtUgLi2eM8NIJ\n" +
              "nLIFD4XnLLRHrAJpcr32n3cCAwEAAQ==\n" +
              "-----END PUBLIC KEY-----\n",
}

export default api