// ** React Imports
// ** Store & Actions
// ** Third Party Components
import { configureStore, } from "@reduxjs/toolkit"
// ** Custom Components
// ** Hooks, context & utils
// ** Styles
// ** Images


import apiReducer from "./api/apiSlice"
// import appReducer from "./app/appSlice"
import stripeReducer from "./stripe/stripeSlice"


const store = configureStore({
  reducer: {
    api: apiReducer,
    // app: appReducer,
    stripe: stripeReducer,
  },
})


export { store, }